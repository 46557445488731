.abt__container{
  overflow: hidden;

}
.us{
    display: flex;
    justify-content: center;
   
    height: 100px;
   margin-top: 5.7rem;

    background-color: rgba(159, 151, 151, 0.273);
}
.us h1{
color: rgb(255, 255, 255);
   padding-top: 2.5rem;
    
}
body {
    background-image: url('https://images.pexels.com/photos/6044235/pexels-photo-6044235.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
    background-size: cover;
  }
  .flex-container{
    display: flex;
    padding-top: 3rem;
    flex-direction: row;
    justify-content:flex-start;
    height: 27rem;
    width:150%;
  }
  .flex-item {
    
    background-color: #65656562;
    padding: 10px;
    margin: 10px;
    flex-basis: calc(33.33% - 20px);
  }
  .flex-item h1 {
    color: white;
    text-align: center;
    padding-top: 2rem;
  }
  .flex-item h3 {
    color: white;
    text-align: center;
    padding-top: 2rem;
    padding-top: 4rem;
  }

.my-image{
    width: auto;
    height: auto;
/*    transform: translateX(50rem);
  margin-top: -35rem; */
}


/* Media queries for mobile */
@media  (max-width: 850px) {
    .flex-container {
      flex-direction: column;
      height: auto;
      width: 100%;
    }
  
    .flex-item {
      flex-basis: 100%;
    }
  
    .my-image {
        transform: none;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
      }
  
    .flex-item p {
      padding-top: 2rem;
    }
  }
