*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
}
.header-area {
  background: linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.5)),
  url(https://images.pexels.com/photos/176851/pexels-photo-176851.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
  background-position: center center;
  background-size: cover;


}
/* default css end */


/* navbar regular css start */
.navbar-area {
  
  z-index:99989 ;
}
.site-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateY(-10%);
  background: rgba(6, 6, 11, 0.404);
  border-bottom: 1px solid #000;
  
  /* Add this line to make the navbar fit to the screen */
}
a.site-logo {
  width: 10px;
  height: 5px;
  text-transform: uppercase;
  color: #444;
  text-decoration: none;
}
a.site-logo img {
  height: 10vh;
  width: 20vh;
  transform: translateY(-40%);
  padding: 20px;
 
  display: flex;
 
}
.site-navbar ul {
 
  list-style: none;
  display: flex;
  

}
.site-navbar ul li a {
  color: #f6f5f5;
  padding: 25px;
  display:inline-block;


  text-decoration: none;
  text-transform: uppercase;
}
.site-navbar ul li a:hover {
  background: rgba(255,255,255,.1);
}
/* navbar regular css end */


/* nav-toggler css start */
.nav-toggler {
  border: 3px solid #fff;
  padding: 5px;
  background-color: transparent;
  cursor: pointer;
  height: 39px;
  display: none;
}
.nav-toggler span,
.nav-toggler span:before,
.nav-toggler span:after {
  width: 28px;
  height: 3px;
  background-color: #fff;
  display: block;
  transition: .3s;
}
.nav-toggler span:before {
  content: '';
  transform: translateY(-9px);
}
.nav-toggler span:after {
  content: '';
  transform: translateY(6px);
}
.nav-toggler.toggler-open span {
  background-color: transparent;
}
.nav-toggler.toggler-open span:before {
  transform: translateY(0px) rotate(45deg);
}
.nav-toggler.toggler-open span:after {
  transform: translateY(-3px) rotate(-45deg);
}
/* nav-toggler css start */


/* intro-area css start */
.intro-area {
  height: calc(100vh - 61px);
  display: flex;
  align-items: center;
  text-align: center;
  color: #fff;
}
.intro-area h2 {
  font-size: 50px;
  font-weight: 300;
  line-height: 50px;
  margin-bottom: 25px;
}
.intro-area p {
  font-size: 18px;
}
/* intro-area css end */
.nav_hamburger {
  display: none;
}


/* mobile breakpoint start */
@media screen and (max-width: 767px) {
  .container {
    max-width: 720px;
  }
  /* navbar css for mobile start */
  .nav-toggler{
    display: block;
  }
  .site-navbar {
    min-height: 60px;
    z-index: 10000;
    
  }
  .site-navbar ul {
    position: absolute;
    width: 100%;
    height: calc(100vh - 60px);
    left: 0;
    top: 60px;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #444;
    background-color: rgba(0,0,0,.75);
    max-height: 10PX;
    overflow: hidden;
    transition: .3s;
  }
  .site-navbar ul li {
    width: 100%;
    text-align: center;
  }
  .site-navbar ul li a {
    padding: 25px;
  }
  .site-navbar ul li a:hover {
    background-color: rgba(255,255,255,.1);
  }
  .site-navbar ul.open {
    max-height: 100vh;
    overflow: visible;
  }
  .intro-area h2 {
    font-size: 36px;
    margin-bottom: 15px;
  }  
  /* navbar css for mobile end */
}

@media screen and (max-width: 992px){

  .nav_hamburger {
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    color: white;
  }
.desk__contents{
  display:none;
}
  .nam_menu_icon {
    font-size: 1.75rem;
    color: var(--tertiary-color);
  }

  .navbar__mob {
    background: linear-gradient(180deg, #050D18 82.03%, #001C3D 100%);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 0rem 7rem;
    position: relative;
  }

  .navbar_mob_close {
    position: absolute;
    top: 1.7rem;
    right: 2rem;
    font-size: 2rem;
    color: white;
  }

  .close_nav_icon {
    cursor: pointer;
  }

  .navbar__mobcontents {
    flex: 0.6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }

  .navmob__link {
    gap: 2.5rem;
    font-family: var(--primary-font);
    font-style: normal;
    align-items: center;
    color: #ffffff;
    font-size: 1.3rem;
    cursor: pointer;
    opacity: 0.6;
    text-align: center;
  }
  

  .navmob__link:hover {
    opacity: 1;
  }  
}