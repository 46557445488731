#model3 .member {
  margin: 20px 15px;
  border-radius: 15px;
  width: 300px;
  padding: 20px 0;
  height: max-content;
  background-color: #37373751;
  transition: all 1s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

#model3 .model-title {
  color: #fff;
  padding-top: 100px;
  text-align: center;
}
#model3 .members {
  padding-top: 50px;
  display: flex; /* added display flex */
  flex-wrap: wrap; /* added flex-wrap wrap */
  justify-content: center; /* added justify-content center */
}

#model3 .member::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 0;
  height: 20px;
  transition: all 1s;
  background: rgb(217, 217, 217);
}

#model3 .member::before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: 0;
  height: 20px;
  transition: all 1s;
  background: rgb(0, 0, 0);
}

#model3 img {
  border-radius: 50%;
  object-fit: cover;
  transition: all 1s;
}

#model3 .description h1 {
  color: rgb(251, 251, 251);
  text-align: center;
  padding-top: 10px;
}

#model3 .description h2 {
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: #1b98f2;
  font-weight: 600;
  font-size: 18px;
  padding-top: 10px;
}

#model3 .description p {
  color: rgb(132, 135, 138);
  text-align: justify;
  padding: 0 25px;
}

#model3 .social-media {
  position: absolute;
  background-color: rgb(5, 7, 8);
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 10px 0;
  justify-content: space-around;
  transition: all 1s;
  width: 0;
  opacity: 0;
  border-radius: 0 0 15px 15px;
  color: #fff;
}

#model3 .description .social-media svg {
  font-size: 30px;
  transition: all 1s;
}

#model3 .member:hover .social-media {
  width: 100%;
  opacity: 1;
}

#model3 .member:hover {
  background-color: rgba(8, 61, 119, 0.2);
  transform: scale(1.01);
}

#model3 .member:hover img {
  transform: scale(1.1);
  border: 2px solid rgb(4, 11, 19);
}

#model3 .description .social-media svg:hover {
  transform: scale(1.1);
  color: #f21b3f;
}

#model3 .member:hover::before {
  width: 50%;
}

#model3 .member:hover::after {
  width: 50%;
}
