.products__container {
    display: flex;
    margin : 3rem;
    margin-top: 100px;
    flex-direction: column;
    align-items: center;
}
.sp-card-1 {
    background-image: linear-gradient(#4d4b4b, #000000);
    height: 1045px;
    width: 535px;
    text-align: left;
    border-radius: 0.7rem;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    -o-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    width: 45rem;
    height: 26rem;
}
.card__container{
    display: flex;
    

}
.pro__desc{
    display: flex;
    flex-direction: column;
}
.desc__container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 23.5rem;
    margin: 1.5rem;
}
.pro__desc{
    display: flex;
    flex-direction: column;
}

/*  .sp-card-1 .shop-button {
        padding-left: 20px;
    } */

/* .sp-card-1 ul {
        padding:0;
    } */

/* .sp-card-1 ul li {
            list-style-type: none
            display: inline-block;                
        } */

/* .sp-card-1 ul li:nth-child(1) {
            padding: 20px 20px;
            float: left;
        } */
.off-label {
                border: 1px solid #fff;
                background: #fff;
                font-size: 11px;
                padding: 7px;
            }

.return-line {
                font-size: 13px;
                padding: 20px 0 15px 0;
                color: #fff;
            }

.sp-card-1 .shop-button a {
                text-decoration: none;
                color: #fff;
                border: 1px solid;
                padding: 10px 15px;
                background: #1f3c3d;

                -webkit-transition: ease-in-out .2s;
                -moz-transition: ease-in-out .2s;
                -o-transition: ease-in-out .2s;
                transition: ease-in-out .2s;
            }

.sp-card-1 .shop-button a:hover {
                background: transparent;
            }

/*         .sp-card-1 ul li:nth-child(2) {
            text-align: right;
            padding: 0;
            float: right;
        } */

/* .sp-card-1 img {
                height: 170px;
                padding: 15px 0 0;

                -webkit-transition: all .3s ease-in;
                -moz-transition: all .3s ease-in;
                -o-transition: all .3s ease-in;
                transition: all .3s ease-in;
            } */
.sp-card-1 img:hover {
                -webkit-transform: scale(0.90);
                -moz-transform: scale(0.90);
                -o-transform: scale(0.90);
                transform: scale(0.90);
            }

.sp-card-1 .price-start {
                text-align: center;
                font-size: 18px;
                color: #fff;
                padding: 18px 0px 0px;
            }

.sp-card-1 .card-title {
        color: #fff;
        font-size: 23px;
        font-weight: 550;
    }

.sp-card-1 .sub-line {
        padding:8px 0px 15px 0px;
        color:#fff;
        font-size:20px;
    }
.sh__btn{
    margin-bottom: 1rem;
}    

.pro__images{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 21rem;
    border-radius: 0.7rem;
}

@media (max-width: 850px) {
   .card__container{
    display: flex;
    flex-direction: column-reverse;
   }
   .pro__images{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 18rem;
    width: 21rem;
}
.sp-card-1 {
    background-image: linear-gradient(#4d4b4b, #000000);
   
    text-align: left;
    border-radius: 0.7rem;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    -o-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    width: 21rem;
    height :47rem
}
.desc__container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 19.5rem;
    margin: 1.5rem;
}
}    

/* @media (max-width: 500px)
{
    .sp-card-1{
        padding: 5rem 2rem;
    }
} */