.mybutton button {
    background-color: #3359ff43;
    color: #fff;
    margin-top: 3rem;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    
  }
  
  .back-button:hover {
    transform: scale(2);
    background-color: #074c96;
  }